import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Image } from '../../Image';
import { SmartLink } from '../../SmartLink';
import { useGeoLocation } from '../../../hooks/useGeoLocation';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';

const BannerModuleInner = ({ data, isAboveTheFold }) => {
  const mobileImage = data?.BannerMobileImage?.entity;
  const desktopImage = data?.BannerDesktopImage?.entity;
  const bannerLink = data?.BannerLink?.uri;
  const bannerClassName = data?.BannerClass;
  const bannerDesktopFullWidth = data?.BannerDesktopFullwidth;

  const styles = {
    main: css`
      width: 100%;
      margin: 0 auto;
      max-width: ${bannerDesktopFullWidth ? 'auto' : '1600px'};
    `,
    mobileImage: css`
      display: block;
      ${theme.mq.small_desktop} {
        display: none;
      }
    `,
    desktopImage: css`
      display: none;
      ${theme.mq.small_desktop} {
        display: block;
      }
    `
  };

  const isVisible = useGeoLocation(data.geolocationFilter, data.countries);

  if (isVisible) {
    const mobileImageTag = (
      <div css={desktopImage ? styles.mobileImage : null}>
        <Image
          entity={mobileImage}
          loading={isAboveTheFold ? 'eager' : 'lazy'}
        />
      </div>
    );
    const desktopImageTag = desktopImage ? (
      <div css={styles.desktopImage}>
        <Image
          entity={desktopImage}
          loading={isAboveTheFold ? 'eager' : 'lazy'}
          noFallbackWidthLimit={bannerDesktopFullWidth}
        />
      </div>
    ) : null;

    return bannerLink ? (
      <div css={styles.main}>
        <SmartLink to={bannerLink} className={bannerClassName}>
          {mobileImageTag}
          {desktopImageTag}
        </SmartLink>
      </div>
    ) : (
      <div className={bannerClassName} css={styles.main}>
        {mobileImageTag}
        {desktopImageTag}
      </div>
    );
  }

  return <div />;
};

BannerModuleInner.defaultProps = {
  isAboveTheFold: false
};

BannerModuleInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool
};

export const BannerModule = ParagraphWrapper(BannerModuleInner);
